import Head from 'next/head';
import {
  Hero,
  About,
  Features,
  Contact,
  Promo,
  Statistics,
  Newsletter,
  Header,
  Footer,
  categories,
} from '@components';

export function Index() {
  return (
    <>
      <Head>
        <title>
          Strona główna | IKSEL Studio Reklamy - Reklama dla Twojej firmy
        </title>

        <meta
          name="description"
          content="IKSEL Studio Reklamy - Profesjonalne projekty reklamowe w atrakcyjnych cenach, Kompleksowa obsługa reklamowa firm, banery siatki mesh kasetony, gadżety reklamowe, druk cyfrowy, niskie nakłady"
        />
      </Head>

      <Header
        id="header"
        logo={{
          imageUrl: '/images/logo_medium.png',
          href: '/#',
        }}
        menu={{
          elements: [
            {
              id: 'header-strona-glowna-link',
              label: 'Strona główna',
              href: '/#',
            },
            {
              id: 'header-oferta-link',
              label: 'Oferta',
              href: '/#oferta',
            },
            {
              id: 'header-aktualnosci-link',
              label: 'Aktualności',
              href: 'https://www.facebook.com/IKSEL.reklama',
            },
            {
              id: 'header-kontakt-link',
              label: 'Kontakt',
              href: '/kontakt',
            },
          ],
        }}
      />

      <Hero
        id="hero"
        heading={{
          main: 'Współtworzymy',
          sub: 'Twój biznes',
        }}
        description={`
          Jesteśmy agencją reklamową, która zapewnia kompleksowe 
          usługi w dziedzinie marketingu tradycyjnego i internetowego`}
        links={[
          {
            id: 'hero-oferta-link',
            href: '/#oferta',
            text: 'Poznaj ofertę',
            mode: 'primary',
          },
          {
            id: 'hero-kontakt-link',
            href: '/kontakt',
            text: 'Nawiąż współpracę',
            mode: 'secondary',
          },
        ]}
      />

      <About
        id="o-nas"
        title="Jak działamy?"
        subtitle={`
          W ciągu wielu lat wypracowaliśmy niezawodny sposób na zapewnienie
          kompleksowej obsługi reklamowej dla naszych Klientów`}
        elements={[
          {
            id: 'o-nas_pomysl-i-projekt',
            title: 'Pomysł i projekt',
            text: `Zgrany i kreatywny zespół grafików zajmie się strategią
          reklamową Twojej firmy. Dobierze materiały i sposób reklamy oraz
          całość zaprojektuje.`,
            image: '/images/how-we-work_1.jpg',
          },
          {
            id: 'o-nas_realizacja-i-druk',
            title: 'Realizacja i druk',
            text: `Natychmiast po Twojej akceptacji przystępujemy do realizacji
          zamówienia. Druk zarówno offsetowy jak i cyfrowy, bogato
          wyposażona introligatornia oraz nowoczesne maszyny gwarantują
          terminowość oraz jakość wykonania.`,
            image: '/images/how-we-work_2.jpg',
          },
          {
            id: 'o-nas_wysylka-i-obsluga',
            title: 'Wysyłka i obsługa posprzedażowa',
            text: `Zamówione produkty można odebrać osobiście bądź wysłać kurierem
          na dowolny adres na terenie Polski. Gwarantujemy kontakt i pomoc
          w zagadnieniach reklamowych oraz stałe bądź okresowe wspieranie
          kampanii reklamowych.`,
            image: '/images/how-we-work_3.jpg',
          },
        ]}
      />

      <Features
        id="oferta"
        title="Czym się zajmujemy?"
        subtitle="Poniej znajduje się lista naszych głównych specjalizacji"
        categories={categories}
      />

      <Statistics
        id="statystyki"
        title="Nasze doświadczenie"
        subtitle=""
        elements={[
          {
            id: 'stat-lata-na-rynku',
            stat: 'ponad 8 lat',
            title: 'Na rynku przez',
          },
          {
            id: 'stat-klienci',
            stat: 'ponad 2600 klientów',
            title: 'Zaufało nam',
          },
          {
            id: 'stat-zamowienia',
            stat: '7000+ zamówień',
            title: 'Zrealizowaliśmy',
          },
        ]}
      />

      <Promo
        id="promo"
        title="Zainteresowany?"
        subtitle="Skorzystaj z naszych usług"
        description={`
          Pomogliśmy rozwinąć skrzydła dziesiątkom firm. Nie wahaj się -
          pomożemy także Twojej! Skontaktuj się z nami w celu otrzymania
          spersonalizowanej oferty.`}
        variant="grid"
        links={[
          {
            id: 'promo-kontakt-link',
            href: '/#kontakt',
            text: 'Jestem zainteresowany',
            mode: 'primary',
          },
        ]}
      />

      <Newsletter
        id="newsletter"
        title="Bądź na bieżąco z najnowszymi promocjami"
        subtitle="Zapisz się do naszego newslettera i otrzymuj powiadomienia o nowych produktach."
      />

      <Contact
        id="kontakt"
        title="Skontaktuj się z nami"
        subtitle="Odpowiemy na każde Twoje pytanie i dołożymy wszelkich starań, by wypromować Ciebie lub Twoją firmę."
        phones={['666 666 320', '666 666 324']}
        emails={['kamil@iksel.com.pl', 'emilia@iksel.com.pl']}
        addresses={['05-822 Milanówek', 'Królewska 41']}
      />

      <Footer
        id="footer"
        logo={{
          imageUrl: '/images/logo_medium.png',
          href: '/#',
        }}
        categories={[
          {
            name: 'Zasady',
            elements: [
              {
                text: 'Polityka prywatności',
                href: '/polityka-prywatnosci',
              },
              {
                text: 'Płatność i dostawa',
                href: '/platnosc-i-dostawa',
              },
              {
                text: 'FAQ',
                href: '/faq',
              },
            ],
          },
          {
            name: 'Pomoc',
            elements: [
              {
                text: 'Jak zamawiać?',
                href: '/jak-zamawiac',
              },
              {
                text: 'Akceptowalne pliki',
                href: '/akceptowalne-pliki',
              },
              {
                text: 'Projektowanie',
                href: '/projektowanie',
              },
            ],
          },
        ]}
      />
    </>
  );
}

export default Index;
